import { Page } from "@atoms/layout/page";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import { Column } from "@molecules/table/table";
import { useRef, useState } from "react";
import { usePersistedState } from "@features/utils/hooks/use-local-storage";
import { Base } from "@atoms/text";
import { SupplierOrderPrepHeader } from "@features/supplier/types";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { Checkbox } from "@atoms/input/input-checkbox";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@features/routes";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import Tabs from "@atoms/tabs";
import SupplierOrderPrepHeaderCard from "@components/supplier/order-cards";
import { Button } from "@atoms/button/button";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { useRecoilState, useSetRecoilState } from "recoil";
import { SendOrderModal } from "../send-order-modal";
import { InputEdit } from "@atoms/input/input-edit";
import { ButtonConfirm } from "@atoms/button/confirm";
import { XIcon } from "@heroicons/react/outline";
import toast from "react-hot-toast";
import { SendOrderHeaders } from "@features/supplier/state/store";
import { SendMultiOrderModal } from "../send-order-modal/multi-order-modal";

export const SupplierSendOrderPage = () => {
  const {
    getOrderPreparationHeaders,
    changeSelectedPrepHeader,
    updateOrderPreparationLineGrp,
    deleteOrderPreparationLineGrp,
    getModesExpedition,
    loading,
  } = useSuppliers();
  const setSendOrderModal = useSetRecoilState(SendOrderModal);
  const setSendMultiOrderModal = useSetRecoilState(SendMultiOrderModal);

  const { current } = useShopLocations();
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<SupplierOrderPrepHeader[]>(
    []
  );

  const [onModifHeader, setOnModifHeader] = useState<SupplierOrderPrepHeader>();
  const [sameFourn, setSameFourn] = useState(false);
  const [modesExpedition, setModesExpedition] = useState<
    { value: string; label: string }[] | null
  >(null);

  const [orderHeaders, setOrderHeaders] = useRecoilState(SendOrderHeaders);

  const [tabIndex, setTabIndex] = usePersistedState<number | string>(
    "user",
    "fourn-envoi-cde-index",
    current && current.items
      ? current!.items.filter(
          (el) => el.modeGest === "L" || el.modeGest === "P"
        )[0].codeTypeProd
      : ""
  );

  const [modeGestion, setModeGestion] = useState(
    current && current.items
      ? current!.items.filter((el) => el.codeTypeProd === tabIndex)[0].modeGest
      : "L"
  );

  const [gridMode, setGridMode] = usePersistedState(
    "user",
    "supplier-send-order-grid-mode",
    {
      activated: false,
    }
  );
  const codeTypeProdRef = useRef<string | number>(
    current && current.items
      ? current!.items.filter(
          (el) => el.modeGest === "L" || el.modeGest === "P"
        )[0].codeTypeProd
      : ""
  );

  const handleUpdateHeader = async (
    orderHeader: SupplierOrderPrepHeader,
    panie: string,
    codeModeExpe: string
  ) => {
    await updateOrderPreparationLineGrp(orderHeader, codeModeExpe, panie);

    const res = await getOrderPreparationHeaders(
      tabIndex.toString(),
      current?.codeLieu
    );
    if (res) setOrderHeaders(res);
    else setOrderHeaders([]);
  };

  useControlledEffect(() => {
    const getModeExp = async () => {
      setModesExpedition([
        { label: "<Aucun>", value: "" },
        ...(await getModesExpedition()).map((el: any) => ({
          label: `${el.label} (${el.value})`,
          value: el.value,
        })),
      ]);
    };

    getModeExp();
  }, []);

  useControlledEffect(() => {
    if (selectedRows.length > 1)
      setSameFourn(
        selectedRows.every((row) => row.codeFourn === selectedRows[0].codeFourn)
      );
    else setSameFourn(false);
  }, [selectedRows]);

  useControlledEffect(() => {
    const handleEvent = async (event: Event) => {
      setTabIndex((previous) => {
        const newCodeTypeProd = previous !== "" ? previous : tabIndex;
        codeTypeProdRef.current = newCodeTypeProd; // Met à jour la référence
        return previous;
      });
      const res = await getOrderPreparationHeaders(
        codeTypeProdRef.current.toString(),
        current?.codeLieu
      );
      if (res) setOrderHeaders(res);
    };

    window.addEventListener("sendOrderEvent", handleEvent);

    return () => {
      window.removeEventListener("sendOrderEvent", handleEvent);
    };
  }, []);

  const orderColumns: Column<SupplierOrderPrepHeader>[] = [
    {
      title: "Fournisseur",
      id: "codeFourn",
      render: (p) => <Base>{p.codeFourn}</Base>,
    },
    {
      title: "Raison-sociale",
      id: "raisSocial",
      render: (p) => <Base>{p.raisSocial}</Base>,
    },
    {
      title: "Panier",
      id: "panie",
      render: (p) => (
        <InputEdit
          inputClassName="min-w-20"
          value={p.panie}
          options={[]}
          onStartEdit={() => setOnModifHeader({ ...p })}
          onStopEdit={() => setOnModifHeader(undefined)}
          onChange={(e) => {
            setOrderHeaders((previous) => {
              const tempTab = [...previous];
              const lineIndex = previous.findIndex((el) => el.find === p.find);
              const tempLine = { ...tempTab[lineIndex] };
              tempLine.panie = e.target.value;
              tempTab[lineIndex] = tempLine;
              return tempTab;
            });
          }}
          onValid={async (newValue) => {
            setOnModifHeader((previous) => {
              handleUpdateHeader(
                { ...p, panie: previous!.panie },
                newValue,
                p.codeModeExpe
              );
              return undefined;
            });
          }}
        />
      ),
    },
    {
      title: "Mode exp.",
      id: "codeModeExpe",
      searchIds: ["codeModeExpe", "libModeExpe"],
      render: (p) => (
        <InputEdit
          value={p.codeModeExpe}
          fieldType="select"
          options={modesExpedition ? modesExpedition : []}
          onStartEdit={() => setOnModifHeader({ ...p })}
          onStopEdit={() => setOnModifHeader(undefined)}
          onChange={(e) => {
            setOrderHeaders((previous) => {
              const tempTab = [...previous];
              const lineIndex = previous.findIndex((el) => el.find === p.find);
              const tempLine = { ...tempTab[lineIndex] };
              tempLine.codeModeExpe = e.target.value;
              tempTab[lineIndex] = tempLine;
              return tempTab;
            });
          }}
          onValid={async (newValue) => {
            setOnModifHeader((previous) => {
              console.log(previous);
              handleUpdateHeader(
                { ...p, codeModeExpe: previous!.codeModeExpe },
                p.panie,
                newValue
              );
              return undefined;
            });
          }}
        />
      ),
    },
    {
      title: "Qte",
      id: "qte",
      render: (p) => <Base className="w-full text-end">{p.qte}</Base>,
    },
    {
      title: "Brut",
      id: "mtBase",
      render: (p) => (
        <Base className="w-full text-end">
          {modeGestion === "P"
            ? p.mtBase.toFixed(current?.devisNbDecimP)
            : p.mtBase.toFixed(current?.devisNbDecim)}
          {current?.devisSymb || "€"}
        </Base>
      ),
    },
    {
      title: "net HT",
      id: "mtNetHt",
      render: (p) => (
        <Base className="w-full text-end">
          {modeGestion === "P"
            ? p.mtNetHt.toFixed(current?.devisNbDecimP)
            : p.mtNetHt.toFixed(current?.devisNbDecim)}
          {current?.devisSymb || "€"}
        </Base>
      ),
    },
    {
      title: "Franco",

      noSearch: true,
      render: (p) => <Base className="w-full">{p.francPort}</Base>,
    },
    {
      title: "Franco atteint",
      id: "francoAtteint",
      noSearch: true,
      render: (p) => (
        <div className="w-full flex justify-center">
          <Checkbox disabled={true} value={p.francoAtteint} />
        </div>
      ),
    },
    {
      title: "Référ",
      id: "ref",
      render: (p) => <Base>{p.ref}</Base>,
    },
    {
      title: "Ligne",
      id: "nbrLigne",
      render: (p) => <Base>{p.nbrLigne}</Base>,
    },
    {
      title: "En envoi",
      id: "enCours",
      render: (p) => (
        <div className="w-full flex justify-center">
          <Checkbox disabled={true} value={p.enCours} />
        </div>
      ),
    },
    {
      title: "Qte cli",
      id: "qteCli",
      render: (p) => <Base className="w-full text-end">{p.qteCli}</Base>,
    },
    {
      title: "Poids",
      id: "totPoids",
      render: (p) => <Base className="w-full text-end">{p.totPoids}</Base>,
    },
    {
      title: "",
      id: "deleteColumn",
      noSearch: true,
      render: (p) => (
        <div className="w-full flex justify-center gap-1">
          <ButtonConfirm
            confirmTitle="Supprimer l'envoi de commande"
            confirmButtonTheme="danger"
            confirmMessage="Êtes-vous sûr de vouloir supprimer cette envoi de commande ?"
            theme="danger"
            size="sm"
            onClick={async (e) => {
              e.stopPropagation();
              const res = await deleteOrderPreparationLineGrp(p);
              if (res) toast.success("Commande en préparation supprimée");
              const headers = await getOrderPreparationHeaders(
                tabIndex.toString(),
                current?.codeLieu
              );
              if (headers) setOrderHeaders(headers);
              else setOrderHeaders([]);
            }}
            icon={({ className }) => <XIcon className={className} />}
            data-tooltip="Supprimer la commande en préparation"
          />
        </div>
      ),
    },
  ];

  return (
    <Page loading={loading}>
      <>
        <div className="flex w-full justify-end">
          <Button
            size="sm"
            disabled={selectedRows.length === 0}
            onClick={() => {
              if (selectedRows.length > 1 && !sameFourn) {
                setSendMultiOrderModal({ open: true, orders: selectedRows });
              } else {
                setSendOrderModal({
                  open: true,
                  codeTypeProd: selectedRows[0].codeTypeProd,
                  codeLieuPourQui: selectedRows[0].codeLieuPourQui,
                  niv: selectedRows[0].niv,
                  orderHeaderList: selectedRows,
                  mode:
                    selectedRows.length > 1 && !sameFourn
                      ? "global"
                      : sameFourn
                      ? "envFourn"
                      : "",
                });
              }
            }}
          >
            {selectedRows.length <= 1
              ? "Envoi unitaire"
              : selectedRows.length === orderHeaders.length
              ? "Envoi global"
              : sameFourn
              ? "Envoi par fournisseur"
              : "Envoi par selection multiple"}
          </Button>
        </div>

        <div className="flex flex-col grow items-center gap-6">
          <Tabs
            tabs={
              current?.items
                ? current?.items
                    ?.filter((el) => el.modeGest === "L" || el.modeGest === "P")
                    .map((el) => {
                      return { value: el.codeTypeProd, label: el.libTypeProd };
                    })
                : []
            }
            value={tabIndex}
            onChange={async (v) => {
              if (current && current.items) {
                setTabIndex(
                  current.items.find((el) => el.codeTypeProd === v)
                    ?.codeTypeProd || "P"
                );

                setModeGestion(
                  current.items.find((el) => el.codeTypeProd === v)?.modeGest ||
                    "P"
                );

                codeTypeProdRef.current =
                  current.items.find((el) => el.codeTypeProd === v)
                    ?.codeTypeProd || "P";
              }
            }}
          />
        </div>
        {/* <Button
          className="mt-4 md:mt-0"
          authorized={functionsAuthorization[`EnvCde-${tabIndex}`].actif}
          //onClick={() => setSendOrderModal({ open: true })}
        >
          Envoi commande
        </Button> */}
        {modesExpedition && (
          <TableGridSwitch
            key={tabIndex}
            className="w-full overflow-x-scroll"
            name="cdeFournHeader"
            data={orderHeaders}
            onChangeMode={(v) => setGridMode({ activated: v })}
            searchModeEnabled={true}
            gridMode={gridMode.activated}
            showPagination={"totalOnly"}
            tableColumns={orderColumns}
            total={orderHeaders.length}
            onClick={(e) => {
              changeSelectedPrepHeader(e);
              const gestionInfo = current?.items?.find(
                (el) => el.modeGest === "L" || el.modeGest === "P"
              );

              navigate(
                ROUTES.SupplierSendOrderLinePage.replace(
                  /:modeGest/,
                  gestionInfo ? gestionInfo.modeGest : "L"
                )
                  .replace(/:codeTypeProd/, tabIndex.toString())
                  .replace(
                    /:libTypeProd/,
                    gestionInfo ? gestionInfo?.libTypeProd : ""
                  )
                  .replace(/:codeFourn/, e.codeFourn)
              );
            }}
            onRequestData={async () => {
              console.log(onModifHeader);
              const res = await getOrderPreparationHeaders(
                tabIndex.toString(),
                current?.codeLieu
              );
              if (res) setOrderHeaders(res);
              else setOrderHeaders([]);
            }}
            renderGrid={(el) => (
              <SupplierOrderPrepHeaderCard
                orderPrep={el}
                modeExpeList={modesExpedition}
              />
            )}
            rowIndex="find"
            onSelect={(e) => setSelectedRows(e)}
          />
        )}
      </>
    </Page>
  );
};
