import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import { useRecoilState, useSetRecoilState } from "recoil";
import { SendOrderModal } from "../send-order-modal";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import { Column } from "@molecules/table/table";
import { useState } from "react";
import { usePersistedState } from "@features/utils/hooks/use-local-storage";
import { Base, Info } from "@atoms/text";
import { SupplierOrderPrepLine } from "@features/supplier/types";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import {
  ArrowNarrowLeftIcon,
  PlusCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { SearchProductModalAtom } from "../search-product-modal";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "@features/routes";
import { BookRender } from "@atoms/book";
import { SupplierOrderPrepProductCard } from "@components/supplier/order-cards";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { InputEdit } from "@atoms/input/input-edit";
import { InputCounter } from "@atoms/input/input-counter";
import { ButtonConfirm } from "@atoms/button/confirm";
import toast from "react-hot-toast";
import { SearchProductsAtom } from "@features/products/state/store";
import { SendOrderLines } from "@features/supplier/state/store";

export const SupplierSendOrderLinePage = () => {
  const setSendOrderModal = useSetRecoilState(SendOrderModal);
  const { modeGest, codeTypeProd, libTypeProd, codeFourn } = useParams<{
    modeGest: string;
    codeTypeProd: string;
    libTypeProd: string;
    codeFourn: string;
  }>();
  const setProducts = useSetRecoilState(SearchProductsAtom);
  const { current } = useShopLocations();
  const [orderLines, setOrderLines] = useRecoilState(SendOrderLines);
  const [modesExpedition, setModesExpedition] = useState<
    { value: string; label: string }[] | null
  >(null);

  const {
    selectedPrepHeader,
    changeSelectedPrepHeader,
    getOrderPreparationLines,
    updateOrderPreparationLine,
    deleteOrderPreparationLine,
    getModesExpedition,
  } = useSuppliers();
  const [searchFieldsValue, setSearchFieldsValue] = usePersistedState<any>(
    "user",
    "product-filters",
    {}
  );
  const setSearchProductModal = useSetRecoilState(SearchProductModalAtom);
  const location = useLocation();
  const navigate = useNavigate();
  const [gridMode, setGridMode] = usePersistedState(
    "user",
    "supplier-send-order-grid-mode",
    {
      activated: false,
    }
  );
  const handleCloseProductSearchModal = () => {
    setSearchProductModal({ open: false, order: undefined });
  };

  useControlledEffect(() => {
    const timer = setTimeout(() => {
      document.title = `Inférence - Envoi cde ${codeFourn} - ${libTypeProd}`;
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useControlledEffect(() => {
    const getModeExp = async () => {
      setModesExpedition([
        { label: "<Aucun>", value: "" },
        ...(await getModesExpedition()).map((el: any) => ({
          label: `${el.label} (${el.value})`,
          value: el.value,
        })),
      ]);
    };

    getModeExp();
  }, []);

  const handleUpdateLine = async (
    orderLine: SupplierOrderPrepLine,
    panie: string,
    codeModeExpe: string,
    qte: number
  ) => {
    await updateOrderPreparationLine(orderLine, codeModeExpe, panie, qte);
    changeSelectedPrepHeader({
      ...selectedPrepHeader,
      panie: panie,
      codeModeExpe: codeModeExpe,
    });
    const res = await getOrderPreparationLines(
      codeTypeProd || "LIVR",
      selectedPrepHeader.codeFourn,
      selectedPrepHeader.codeLieuLivr,
      panie,
      codeModeExpe
    );
    if (res) {
      if (res.length === 0) navigate(ROUTES.SupplierSendOrderPage);
      setOrderLines(res);
    } else setOrderLines([]);
  };

  // const handleBrouillardModal = async (force = false) => {
  //   if (codeTypeProd && orderLines && orderLines.length > 0) {
  //     const res = await createOrderBrouillard(
  //       codeTypeProd,
  //       orderLines[0].codeLieuPourQui,
  //       orderLines[0].niv,
  //       selectedPrepHeader
  //     );
  //     if (res) {
  //       if (res.response === "OK") {
  //         await SupplierApiClient.processingOrderPrepHeader(
  //           codeTypeProd,
  //           orderLines[0].codeLieuPourQui,
  //           orderLines[0].niv,
  //           selectedPrepHeader,
  //           true
  //         );
  //         if (res.msg !== "") toast.success(res.msg);
  //         const resBrouillard = await getOrderBrouillard(
  //           codeTypeProd,
  //           orderLines[0].codeLieuPourQui,
  //           orderLines[0].niv,
  //           selectedPrepHeader
  //         );
  //         if (resBrouillard)
  //           setSendOrderModal({
  //             open: true,
  //             codeTypeProd: codeTypeProd,
  //             codeLieuPourQui: orderLines[0].codeLieuPourQui,
  //             niv: orderLines[0].niv,
  //             orderBrouillard: resBrouillard,
  //           });
  //       }
  //       if (res && res.response === "?") {
  //         setConfirmModal({
  //           theme: "warning",
  //           icon: () => (
  //             <FaExclamationTriangle className="text-red-600 h-6 w-6" />
  //           ),
  //           open: true,
  //           title: "",
  //           message: res.msg,
  //           onAccept: async () => {
  //             await SupplierApiClient.processingOrderPrepHeader(
  //               codeTypeProd,
  //               orderLines[0].codeLieuPourQui,
  //               orderLines[0].niv,
  //               selectedPrepHeader,
  //               false
  //             );
  //             handleBrouillardModal();
  //           },
  //         });
  //       }
  //     }
  //   }
  // };

  useControlledEffect(() => {
    const messageHandler = async (event: MessageEvent) => {
      if (event.data.action === "closeModal") {
        handleCloseProductSearchModal();
        if (event.data.product) {
          const resLine = await getOrderPreparationLines(
            codeTypeProd || "LIVR",
            selectedPrepHeader.codeFourn,
            selectedPrepHeader.codeLieuLivr,
            selectedPrepHeader.panie,
            selectedPrepHeader.codeModeExpe
          );
          if (resLine) setOrderLines(resLine);

          window.location.reload();

          //createOrder(product as ProductType)
        }
      }
    };

    window.addEventListener("message", messageHandler);

    // Nettoyage de l'événement lors du démontage du composant
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  const orderColumns: Column<SupplierOrderPrepLine>[] = [
    {
      title: "Article",
      id: "",
      render: (p) => (
        <div
          className="flex flex-row items-center space-x-4 cursor-pointer"
          onClick={() =>
            navigate(
              ROUTES.Product.replace(/:ean/, p.ean13).replace(
                /:type/,
                p.tooltip.modeGest
              ),
              { state: { from: location.pathname } }
            )
          }
        >
          <div className="w-10 print:hidden">
            <BookRender
              src={p.imageURL}
              productType={p.tooltip.modeGest}
              data-tooltip={`<img
                src="${p.imageURL}"     
                style="max-width: 400px; max-height: 400px; width: 100%; height: auto;"/>`}
            />
          </div>
          <div className="flex flex-col">
            <Base
              className="block overflow-hidden whitespace-nowrap text-ellipsis print:whitespace-normal max-w-xs"
              data-tooltip={p.desig}
            >
              {p.desig}
            </Base>
            <Info>{p.ean13}</Info>
          </div>
        </div>
      ),
    },
    {
      title: "Rayon",
      id: "codeRayon",
      render: (p) => <Base>{p.codeRayon}</Base>,
    },
    {
      title: "Éditeur",
      id: "codeEdit",
      render: (p) => <Base>{p.codeEdit}</Base>,
    },
    {
      title: "PU TTC",
      id: "raisSocial",
      type: "price",
      render: (p) => (
        <Base className="w-full text-end">
          {modeGest === "P"
            ? p.prixBase.toFixed(current?.devisNbDecimP)
            : p.prixBase.toFixed(current?.devisNbDecim)}
          {current?.devisSymb || "€"}
        </Base>
      ),
    },
    {
      title: "Orig.",
      id: "orig",
      render: (p) => <Base>{p.orig}</Base>,
    },
    {
      title: "Fournisseur",
      id: "codeFounr",
      render: (p) => <Base>{p.codeFourn}</Base>,
    },
    {
      title: "Exp.",
      id: "codeModeExpe",
      searchIds: ["codeModeExpe", "libModeExpe"],
      render: (p) => (
        <InputEdit
          value={p.codeModeExpe}
          fieldType="select"
          options={modesExpedition ? modesExpedition : []}
          onChange={(e) => {
            setOrderLines((previous) => {
              const tempTab = [...previous];
              const lineIndex = previous.findIndex((el) => el.cle === p.cle);
              const tempLine = { ...tempTab[lineIndex] };
              tempLine.codeModeExpe = e.target.value;
              tempTab[lineIndex] = tempLine;
              return tempTab;
            });
          }}
          onValid={async (newValue) => {
            handleUpdateLine(p, p.panie, newValue, p.qte);
          }}
        />
      ),
    },
    {
      title: "Opé.",
      id: "codeOpe",
      render: (p) => <Base>{p.codeOpe}</Base>,
    },

    {
      title: "Panier",
      id: "panie",
      render: (p) => (
        <InputEdit
          inputClassName="min-w-20"
          value={p.panie}
          options={[]}
          onChange={(e) => {
            setOrderLines((previous) => {
              const tempTab = [...previous];
              const lineIndex = previous.findIndex((el) => el.cle === p.cle);
              const tempLine = { ...tempTab[lineIndex] };
              tempLine.panie = e.target.value;
              tempTab[lineIndex] = tempLine;
              return tempTab;
            });
          }}
          onValid={async (newValue) => {
            await handleUpdateLine(
              { ...p, panie: selectedPrepHeader.panie },
              newValue,
              p.codeModeExpe,
              p.qte
            );
          }}
        />
      ),
    },
    {
      title: "Quantité",
      id: "qte",
      render: (p) => (
        <div className="text-end" style={{ maxWidth: 128, minWidth: 96 }}>
          <InputCounter
            value={p.qte}
            onChange={async (newValue) => {
              await handleUpdateLine(
                { ...p, panie: selectedPrepHeader.panie },
                p.panie,
                p.codeModeExpe,
                newValue
              );
            }}
          />
        </div>
      ),
    },
    {
      title: "Dispo. vente",
      id: "qteDispoVente",
      render: (p) => <Base className="w-full text-end">{p.qteDispoVente}</Base>,
    },
    {
      title: "",
      id: "deleteColumn",
      noSearch: true,
      render: (p) => (
        <div className="w-full flex justify-center gap-1">
          <ButtonConfirm
            theme="danger"
            confirmTitle="Supprimer ligne de commande"
            confirmButtonTheme="danger"
            confirmMessage={`Êtes-vous sûr de vouloir supprimer l'article: ${p.desig}?`}
            size="sm"
            onClick={async (e) => {
              e.stopPropagation();
              const res = await deleteOrderPreparationLine(p);
              if (res)
                toast.success("Ligne de commande en préparation supprimée");
              const lines = await getOrderPreparationLines(
                codeTypeProd || "LIVR",
                selectedPrepHeader.codeFourn,
                selectedPrepHeader.codeLieuLivr,
                selectedPrepHeader.panie,
                selectedPrepHeader.codeModeExpe
              );
              if (lines) {
                if (lines.length === 0) navigate(ROUTES.SupplierSendOrderPage);
                setOrderLines(lines);
              } else setOrderLines([]);
            }}
            icon={({ className }) => <XIcon className={className} />}
            data-tooltip="Supprimer la commande en préparation"
          />
        </div>
      ),
    },
  ];

  return (
    <Page>
      <>
        <div className="flex justify-end gap-2 ">
          <Button
            theme="danger-outlined"
            onClick={() => {
              navigate(ROUTES.SupplierSendOrderPage);
            }}
            icon={(p) => <ArrowNarrowLeftIcon {...p} />}
          >
            Retour
          </Button>
          <Button
            theme="outlined"
            icon={(p) => <PlusCircleIcon {...p} />}
            onClick={() => {
              if (searchFieldsValue)
                // Juste pour le linter
                setSearchFieldsValue({
                  type: selectedPrepHeader.codeTypeProd,
                  _advanced: true,
                  external_db: true,
                  fournisseur: {
                    label: selectedPrepHeader.codeFourn,
                    value: selectedPrepHeader.codeFourn,
                    codeRech: selectedPrepHeader.codeFourn,
                  },
                });
              setProducts({
                pageCount: 0,
                totalRows: 0,
                pageNumber: 0,
                pageSize: 0,
                items: [],
              });
              setSearchProductModal({ open: true });
            }}
          >
            Ajouter un article
          </Button>
          <Button
            onClick={() => {
              if (codeTypeProd && orderLines)
                setSendOrderModal({
                  open: true,
                  codeTypeProd: codeTypeProd,
                  codeLieuPourQui: orderLines[0].codeLieuPourQui,
                  niv: orderLines[0].niv,
                  orderBrouillard: undefined,
                  orderHeaderList: [selectedPrepHeader],
                });
            }}
            // onClick={async () => {
            //   if (codeTypeProd && orderLines && orderLines.length > 0) {
            //     const res = await createOrderBrouillard(
            //       codeTypeProd,
            //       orderLines[0].codeLieuPourQui,
            //       orderLines[0].niv,
            //       selectedPrepHeader
            //     );
            //     if (res) {
            //       if (res.response === "OK") {
            //         const resBrouillard = await getOrderBrouillard(
            //           codeTypeProd,
            //           orderLines[0].codeLieuPourQui,
            //           orderLines[0].niv,
            //           selectedPrepHeader
            //         );
            //         if (resBrouillard) setSendOrderModal({ open: true });
            //       }
            //       if (res && res.response === "?") {
            //         setConfirmModal({
            //           theme: "warning",
            //           icon: () => (
            //             <FaExclamationTriangle className="text-red-600 h-6 w-6" />
            //           ),
            //           open: true,
            //           title: "",
            //           message: res.msg,
            //           onAccept: async () => {
            //             await SupplierApiClient.processingOrderPrepHeader(
            //               codeTypeProd,
            //               orderLines[0].codeLieuPourQui,
            //               orderLines[0].niv,
            //               selectedPrepHeader,
            //               false
            //             );
            //             await createOrderBrouillard(
            //               codeTypeProd,
            //               orderLines[0].codeLieuPourQui,
            //               orderLines[0].niv,
            //               selectedPrepHeader,
            //               true
            //             );
            //           },
            //         });
            //       }
            //     }
            //   }
            // }}
          >
            Envoi de commande
          </Button>
        </div>
        {modesExpedition && (
          <TableGridSwitch
            className="w-full overflow-x-scroll"
            name="cdeFournLigne"
            data={orderLines}
            total={orderLines.length}
            showPagination={"totalOnly"}
            onChangeMode={(v) => setGridMode({ activated: v })}
            gridMode={gridMode.activated}
            tableColumns={orderColumns}
            onRequestData={async () => {
              const res = await getOrderPreparationLines(
                codeTypeProd || "LIVR",
                selectedPrepHeader.codeFourn,
                selectedPrepHeader.codeLieuLivr,
                selectedPrepHeader.panie,
                selectedPrepHeader.codeModeExpe
              );
              if (res) setOrderLines(res);
              else setOrderLines([]);
            }}
            renderGrid={(el) => (
              <SupplierOrderPrepProductCard
                product={el}
                modeGest={modeGest as "DISQ" | "LIVR" | "PRES" | "PAPE"}
                modeExpeList={modesExpedition}
              />
            )}
            gridClassName={
              "grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3"
            }
          />
        )}
      </>
    </Page>
  );
};
