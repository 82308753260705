import { Button } from "@atoms/button/button";
import { PageLoader } from "@atoms/layout/page-loader";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Base, BaseBold, Title } from "@atoms/text";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { SendOrderHeaders } from "@features/supplier/state/store";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { SupplierOrderPrepHeader } from "@features/supplier/types";
import { PdfModal } from "@molecules/pdf-viewer/pdf-viewer-modal";
import { Table } from "@molecules/table";
import { useState } from "react";
import toast from "react-hot-toast";
import { atom, useRecoilState, useSetRecoilState } from "recoil";

type SendMultiOrderModalType = {
  open: boolean;
  orders: SupplierOrderPrepHeader[];
};

export const SendMultiOrderModal = atom<SendMultiOrderModalType>({
  key: "SendMultiOrderModalAtom",
  default: { open: false, orders: [] },
});

export const SendMultiSupplierOrderModal = () => {
  const [sendMultiOrderModal, setSentMultiOrderModal] =
    useRecoilState(SendMultiOrderModal);
  const {
    getOrderPreparationHeaders,
    createOrderBrouillard,
    getOrderBrouillard,
    getSupplierDocument,
    sendFournOrder,
  } = useSuppliers();
  const setOrderHeaders = useSetRecoilState(SendOrderHeaders);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(0);
  const [success, setSuccess] = useState<SupplierOrderPrepHeader[]>([]);
  const [fails, setFails] = useState<SupplierOrderPrepHeader[]>([]);
  const setPdfModal = useSetRecoilState(PdfModal);

  const { current } = useShopLocations();

  const handleClose = () => {
    setSentMultiOrderModal({ open: false, orders: [] });
    setLoading(false);
    setCounter(0);
    setSuccess([]);
    setFails([]);
  };

  const handleSend = async () => {
    setLoading(true);
    sendMultiOrderModal.orders.map(async (el) => {
      try {
        setCounter((previous) => previous + 1);
        const res = await createOrderBrouillard(
          el.codeTypeProd,
          el.codeLieuPourQui,
          el.niv,
          el,
          true
        );
        if (res) {
          toast.success("Commande créé avec succès");
          setSuccess((previous) => {
            const temp = [...previous];
            temp.push(el);
            return temp;
          });
          const brouil = await getOrderBrouillard(
            el.codeTypeProd,
            el.codeLieuPourQui,
            el.niv,
            el
          );
          if (brouil) {
            await sendFournOrder(
              el.codeTypeProd,
              el.codeLieuPourQui,
              el.niv,
              el,
              brouil
            );
          }
        }
      } catch {
        toast.error("Erreur lors de l'envoi");
        setFails((previous) => {
          const temp = [...previous];
          temp.push(el);
          return temp;
        });
      }
    });
  };

  return (
    <Modal
      className="sm:max-w-[900px]"
      open={sendMultiOrderModal.open}
      onClose={async () => handleClose()}
    >
      <ModalContent title={"Paramètres d'envoi de commande(s)"}>
        <div className="grow">
          {loading && (
            <div className="w-full h-full flex gap-4 justify-center items-center">
              <PageLoader />
              <Title className="h-full !text-gray-500">Envoi en cours</Title>
              <Title className="h-full !text-gray-500">{`${counter}/${sendMultiOrderModal.orders.length}`}</Title>
            </div>
          )}

          {!loading && counter === 0 && (
            <div className="flex flex-col gap-4 text-center">
              <Base>
                {`Vous êtes sur le point d'envoyer ${sendMultiOrderModal.orders.length} commandes. Souhaitez vous continuer?`}
              </Base>
              <Button
                theme="valid"
                className="self-center"
                onClick={async () => {
                  let modeGest: string = "";
                  if (current?.items) {
                    modeGest =
                      current.items.find(
                        (el) =>
                          el.codeTypeProd ===
                          sendMultiOrderModal.orders[0].codeTypeProd
                      )?.modeGest || "P";
                  }

                  const createRes = await createOrderBrouillard(
                    sendMultiOrderModal.orders[0].codeTypeProd,
                    sendMultiOrderModal.orders[0].codeLieuPourQui,
                    sendMultiOrderModal.orders[0].niv,
                    sendMultiOrderModal.orders[0],
                    true
                  );

                  if (createRes) {
                    const brouil = await getOrderBrouillard(
                      sendMultiOrderModal.orders[0].codeTypeProd,
                      sendMultiOrderModal.orders[0].codeLieuPourQui,
                      sendMultiOrderModal.orders[0].niv,
                      sendMultiOrderModal.orders[0]
                    );

                    if (brouil) {
                      const res = await getSupplierDocument(
                        brouil.numBroui,
                        "brouiCde",
                        modeGest || "P",
                        sendMultiOrderModal.orders[0].codeTypeProd,
                        current?.codeLieu || "",
                        current?.centr || false
                      );
                      if (res) {
                        setPdfModal({
                          open: true,
                          pdfProps: {
                            blobFile: res,
                            iframeSize: {
                              width: "80%",
                              height: "90%",
                            },
                            iFrameClassname:
                              "w-full h-full flex justify-center items-center",
                          },
                          itemList: sendMultiOrderModal.orders,
                          onNext: async (itemList, index) => {
                            const res = await createOrderBrouillard(
                              itemList[index].codeTypeProd,
                              itemList[index].codeLieuPourQui,
                              itemList[index].niv,
                              itemList[index],
                              true
                            );
                            if (res) {
                              const brouil = await getOrderBrouillard(
                                itemList[index].codeTypeProd,
                                itemList[index].codeLieuPourQui,
                                itemList[index].niv,
                                itemList[index]
                              );
                              if (brouil) {
                                const resBis = await getSupplierDocument(
                                  brouil.numBroui,
                                  "brouiCde",
                                  modeGest || "P",
                                  sendMultiOrderModal.orders[0].codeTypeProd,
                                  current?.codeLieu || "",
                                  current?.centr || false
                                );
                                return resBis;
                              }
                            }
                          },
                        });
                      }
                    }
                  }
                }}
              >
                Imprimer brouillards
              </Button>
              <Button
                theme="valid"
                className="self-center"
                onClick={async () => {
                  try {
                    setLoading(true);
                    await handleSend();
                  } catch {
                    toast.error("ERROR");
                    //setLoading(false);
                  } finally {
                    setTimeout(async () => {
                      const res = await getOrderPreparationHeaders(
                        sendMultiOrderModal.orders[0].codeTypeProd,
                        current?.codeLieu
                      );
                      if (res) setOrderHeaders(res);
                      else setOrderHeaders([]);
                      setLoading(false);
                    }, 2000);
                  }
                }}
              >
                Envoyer
              </Button>
            </div>
          )}
          {!loading && counter === sendMultiOrderModal.orders.length && (
            <div className="flex flex-col gap-4">
              <Base>Succès: {success.length}</Base>
              <Base>Erreurs: {fails.length}</Base>
              {fails.length > 0 && (
                <div className="flex flex-col gap-2">
                  <BaseBold className="self-center">
                    Liste des commandes ayant rencontré un problème:
                  </BaseBold>
                  <Table
                    name="truc"
                    columns={[
                      {
                        title: "Fournisseur",
                        id: "codeFourn",
                        render: (p) => <Base>{p.codeFourn}</Base>,
                      },
                      {
                        title: "Raison-sociale",
                        id: "raisSocial",
                        render: (p) => <Base>{p.raisSocial}</Base>,
                      },
                      {
                        title: "Panier",
                        id: "panie",
                        render: (p) => <Base>{p.panie}</Base>,
                      },
                      {
                        title: "Mode exp.",
                        id: "codeModeExpe",
                        render: (p) => <Base>{p.codeModeExpe}</Base>,
                      },
                    ]}
                    data={[...fails]}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
};
